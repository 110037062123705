import React from "react";
import PropTypes from "prop-types";
import styles from "./Quote.module.scss";

const Quote = ({ children, author }) => {
  if (author) {
    return (
      <div className={styles.container}>
        <blockquote>{children}</blockquote>
        <div>— {author}</div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <blockquote>{children}</blockquote>
    </div>
  );
};

Quote.defaultProps = {
  author: null,
};

Quote.propTypes = {
  children: PropTypes.node.isRequired,
  author: PropTypes.string,
};

export default Quote;
