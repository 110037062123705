import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import withGraphQLErrors from "../hocs/withGraphQLErrors";
import ContentLayout from "../primitives/ContentLayout";
import SectionLayout from "../primitives/SectionLayout";
import LinkButton from "../primitives/LinkButton";
import ColumnLayout from "../primitives/ColumnLayout";
import Quote from "../primitives/Quote";
import LinkWithArrow from "../primitives/LinkWithArrow";
import atelierPhoto from "../images/services/atelier-de-co-conception.jpg";
import designCentreUsagerPhoto from "../images/services/design-centre-usager.jpg";
import diagDesignPhoto from "../images/services/diagnostic-design-bpi.jpg";
import designThinkingPhoto from "../images/services/innovation-design-thinking.jpg";
import maquetteInteractivePhoto from "../images/services/maquette-interactive-uxdesign.jpg";
import maquettePapierPhoto from "../images/services/maquette-papier-ux-design.jpg";
import observation1Photo from "../images/services/observations-usages-design-1.jpg";
import observation2Photo from "../images/services/observations-usages-design-2.jpg";
import prototypagePhoto from "../images/services/prototypage-interface.jpg";
import uiDesignPhoto from "../images/services/ui-design.jpg";
import styles from "../styles/services.module.scss";

export const query = graphql`
  query ServicesPageQuery {
    page: sanityRoute(slug: { current: { eq: "services" } }) {
      page {
        title
        meta {
          metaDescription
          metaTitle
        }
        openGraphImage {
          asset {
            _id
          }
        }
      }
    }
  }
`;

const ServicesPage = ({ data }) => {
  const { page = {} } = data || {};

  return (
    <Layout>
      <Seo
        title={
          get(page, "page.meta.metaTitle", "") || get(page, "page.title", "")
        }
        description={get(page, "page.meta.metaDescription", "")}
        image={get(page, "page.openGraphImage", null)}
      />
      <ContentLayout size="medium">
        <ColumnLayout image={<img src={atelierPhoto} alt="" />} reverse>
          <header>
            <h1 className={styles.pageTitle}>Nos services</h1>
            <p className={styles.pageSubtitle}>
              Des ateliers de conception participative pour que créativité et
              innovation s’expriment dans une intelligence collective.
            </p>
          </header>
        </ColumnLayout>
      </ContentLayout>

      <ContentLayout size="regular">
        <h2 className={styles.sectionTitle}>
          Design stratégique :<br />
          <span className={styles.sectionSubtitle}>
            une opportunité pour innover et se développer
          </span>
        </h2>
        <ColumnLayout image={<img src={diagDesignPhoto} alt="" />} fill>
          <h3 className={styles.articleTitle}>
            Diagnostic design&nbsp;: le design intégré dans votre stratégie
            d’entreprise
          </h3>
          <p>
            Plus le design s’inscrit en amont de votre projet - dès la rédaction
            du cahier des charges, voire dans la stratégie même de votre
            entreprise - plus il porte ses fruits. Avant d’amorcer un projet, de
            nombreux clients font appel à notre agence afin de réaliser un{" "}
            <strong>Diag design</strong>. Aujourd’hui pris en charge à 50% par
            la BPI, cet accompagnement permet de définir une stratégie Design
            pour votre entreprise à travers une analyse synthétique de votre
            activité et de son marché.
          </p>
          <LinkButton to="/contact/">Prendre RDV</LinkButton>
        </ColumnLayout>
        <Quote>
          Intégrer le design dès le début d’un projet évite de réduire son
          intervention à un surfaçage final et lui permet réellement de faire
          œuvre de création.
        </Quote>
        <ColumnLayout image={<img src={designThinkingPhoto} alt="" />} reverse>
          <h3 className={styles.articleTitle}>
            Innover par le design&nbsp;: gagner des parts de marché
          </h3>
          <p>
            Qu’elles soient privées ou publiques, les organisations ne cessent
            de réfléchir à l’amélioration continue de leurs produits ou de leurs
            services. Nos clients qui ont su intégrer le design dans leur ADN en
            ont fait un véritable levier de croissance, qui s’est traduit sur le
            marché, par une hausse conséquente de leur chiffre d’affaire et un
            avantage concurrentiel.
          </p>
          <p>
            <LinkWithArrow to="/projets/#clients">
              Voir la liste de nos clients
            </LinkWithArrow>
          </p>
        </ColumnLayout>
        <ColumnLayout image={<img src={designCentreUsagerPhoto} alt="" />} fill>
          <h3 className={styles.articleTitle}>L’innovation centrée-usager</h3>
          <p>
            Notre méthode repose sur l’idée que le métissage des cultures métier
            constituent le potentiel créatif des{" "}
            <strong>séances d’idéation</strong>. Un dialogue s’ouvre entre
            expert, ingénieur, marketeur, designer et usager pour comprendre les
            besoins du client et les vrais besoins utilisateur souvent
            inexprimés ou inconscients. Tour à tour, le designer fait parler les
            uns et les autres, synthétise les idées et fait ressortir les pistes
            de conception grâce aux outils tels que le croquis, le
            <strong>
              <i>wall communication</i>
            </strong>{" "}
            et le{" "}
            <strong>
              <i>brainstorming</i>
            </strong>{" "}
            qui favorisent l’innovation collective.
          </p>
        </ColumnLayout>
      </ContentLayout>

      <SectionLayout backgroundColor={{ value: "#ce474e" }}>
        <ContentLayout size="regular">
          <h2 className={styles.sectionTitle}>
            Design d’interfaces :<br />
            <span className={styles.sectionSubtitle}>
              accompagner, comprendre, co-construire, tester
            </span>
          </h2>
          <ColumnLayout
            image={
              <div>
                <img src={observation1Photo} alt="" />
                <img src={observation2Photo} alt="" />
              </div>
            }
          >
            <h3 className={styles.articleSubtitle}>Phase d’immersion</h3>
            <h4 className={styles.articleTitle}>L’observation d’usages</h4>
            <p>
              Suivant une démarche <strong>proche de l’ethnographie</strong>,
              nos designers réalisent des observations d’usages en amont de tout
              projet pour comprendre le contexte métier dans toute sa complexité
              et identifier les interactions entre les usagers et les outils
              existants. Avec son regard candide, le designer interroge des
              utilisateurs aux profils variés, idéalement sur le terrain car
              c’est là que se révèlent les incohérences des outils et les gestes
              devenus inconscients, qui ne ressortent pas lors d’une simple
              interview.
            </p>
            <p>
              Cette phase d’immersion est fondamentale pour identifier les
              enjeux de conception. Elle fait émerger les moments clés de
              l’expérience utilisateur et les points de frictions importants à
              résoudre.
            </p>
          </ColumnLayout>
        </ContentLayout>
        <ContentLayout size="regular">
          <SectionLayout backgroundColor={{ value: "#ebebe9" }}>
            <div style={{ padding: "0 20px 24px 40px" }}>
              <h3 className={styles.articleSubtitle}>Phase de design</h3>
              <h4 className={styles.articleTitle}>
                Design d’interface&nbsp;: concevoir ensemble de manière
                itérative
              </h4>
            </div>
            <ColumnLayout image={<img src={prototypagePhoto} alt="" />} reverse>
              <h5 className={styles.articleTitleSmall}>Maquettes papiers</h5>
              <p>
                Lors des ateliers de co-design avec les utilisateurs, experts
                métier, développeurs et designers, on explore les usages, on
                matérialise les pistes. Les designers ont pour mission d'ouvrir
                les perspectives, en donnant à voir les potentialités des
                interfaces numériques.
              </p>
              <p>
                Des maquettes papier peuvent alors être réalisées et mis au
                centre de la table pour que tous les acteurs du projet
                conçoivent ensemble l’interface. Utiliser des outils simples
                comme le papier permet à chacun de proposer, d’élaborer ses
                idées.
              </p>
            </ColumnLayout>
            <Quote>
              Le design d’interface est le cœur de notre métier. Concevoir en
              nous appuyant sur des 'objets intermédiaires' montrant les
              interactions successives d'un outil.
            </Quote>
            <ColumnLayout image={<img src={maquettePapierPhoto} alt="" />}>
              <h5 className={styles.articleTitleSmall}>
                UX design, storyboards
              </h5>
              <p>
                Les prototypes papier ont l’avantage de pouvoir être manipulés
                par les utilisateurs&nbsp;: le support vidéo permet de garder
                trace de leurs actions, de leurs échecs, de leurs trouvailles et
                de leurs réactions. Et de transmettre les avancées d’un projet
                au cours de sa menée.
              </p>
              <p>
                À un certain stade d’avancement, les maquettes se complexifient
                et deviennent difficiles à manier : les fonctions sont
                clarifiées dans des storyboards, re-scénarisées, afin de voir si
                tous les cas d’usages sont couverts.
              </p>
            </ColumnLayout>
            <ColumnLayout
              image={<img src={maquetteInteractivePhoto} alt="" />}
              reverse
            >
              <h5 className={styles.articleTitleSmall}>
                Maquettes interactives et prototypage
              </h5>
              <p>
                Les maquettes interactives permettent de tester des concepts,
                des interactions, des pistes de fonctions, auprès des acteurs du
                projet et des utilisateurs. Elles peuvent être mises en œuvre
                plus ou moins tôt dans le projet et fluidifient la communication
                en interne.
              </p>
              <p>
                Certains projets nécessitent des prototypes développés
                rapidement lors de sprint, par exemple, qui permettent de tester
                rapidement les interfaces avec des tests utilisateurs, avant de
                se lancer dans le développement d’un produit de manière
                industrielle.
              </p>
            </ColumnLayout>
            <div style={{ textAlign: "center" }}>
              <LinkButton to="/projets/">Voir des projets</LinkButton>
            </div>
          </SectionLayout>
        </ContentLayout>
        <SectionLayout backgroundColor={{ value: "#ce474e" }}>
          <ContentLayout>
            <ColumnLayout image={<img src={uiDesignPhoto} alt="" />}>
              <h3 className={styles.articleSubtitle}>Phase de design</h3>
              <h4 className={styles.articleTitle}>
                UI design&nbsp;: signer une identité et remporter l’adhésion des
                futurs utilisateurs
              </h4>
              <p>
                L’esthétique d’une interface n’est pas anecdotique&nbsp;: elle
                signe son identité. Par le design graphique, les designers
                assurent sa lisibilité, son accessibilité et sa compréhension
                intuitives, vecteurs d’adhésion des utilisateurs. Au moment de
                sa diffusion, une interface doit s'appuyer sur une identité
                graphique marquée et singulière&nbsp;: on peut choisir
                d'inscrire les interfaces dans les codes graphiques d'un domaine
                donné, ou bien au contraire d'adopter une direction disruptive
                en utilisant des codes graphiques inattendus…
              </p>
            </ColumnLayout>
            <div style={{ textAlign: "center" }}>
              <LinkButton to="/contact/">
                Contactez-nous pour votre projet
              </LinkButton>
            </div>
          </ContentLayout>
        </SectionLayout>
      </SectionLayout>
    </Layout>
  );
};

export default withGraphQLErrors()(ServicesPage);
